<template>
  <a-tooltip v-if="tooltipContent" :placement="tooltipPlacement">
    <template slot="title">
      <span v-html="tooltipContent" @click="handleTooltipClick"></span>
    </template>
    <a-button class="button" :type="type" :loading="isLoading" :disabled="isDisabled" @click.prevent="handleClick">
      <slot v-if="useSlot" />
      <template v-else>
        <font-awesome-icon v-if="icon !== 'none' && !isSuffixIcon" class="icon" :icon="icon" />
        {{ buttonText }}
        <font-awesome-icon v-if="icon !== 'none' && isSuffixIcon" class="icon m-left-5" :icon="icon" />
      </template>
    </a-button>
  </a-tooltip>
  <a-button v-else class="button" :type="type" :loading="isLoading" :disabled="isDisabled" @click.prevent="handleClick">
    <slot v-if="useSlot" />
    <template v-else>
      <font-awesome-icon v-if="icon !== 'none' && !isSuffixIcon" class="icon" :icon="icon" />
      {{ buttonText }}
      <font-awesome-icon v-if="icon !== 'none' && isSuffixIcon" class="icon m-left-5" :icon="icon" />
    </template>
  </a-button>
</template>

<script>
export default {
  name: "BaseButton",
  components: {},
  props: {
    type: {
      type: String,
      required: false,
      default: () => {
        "";
      },
    },
    icon: {
      type: String,
      default: "none",
    },
    isLoading: Boolean,
    isDisabled: Boolean,
    isSuffixIcon: Boolean,
    buttonText: String,
    handleClick: Function,
    useSlot: Boolean,
    tooltipContent: {
      type: String,
      default: "",
    },
    tooltipPlacement: {
      type: String,
      default: "top",
    },
    handleTooltipClick: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
button:disabled {
  opacity: 0.6;
}

::v-deep .ant-tooltip-inner a {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
</style>
